footer.site-info {
	font-size: smaller;
	margin-top: 2.5rem;
	margin-bottom: 6.5rem;
	border-top: 1px solid var(--footer-border-color);
	padding: 0 1rem;

	a {
	
	  &.feed-subscribe {
	    display: flex;
	    align-items: center;
	    
	    span {
	      margin-left: .25rem
	    }
	    
	    svg {
	      fill: currentColor;
	      color: var(--footer-icon-color);
	      width: 1rem;
        height: 1rem;

	      &:hover {
	        color: var(--footer-icon-color-hover)
	      }
	    }
	    
	    &:hover svg {
	      color: var(--footer-icon-color-hover)
	    }
	  }  
	}
}
@media (min-width: 1024px) {
	footer.site-info {
	  padding-top: 1.5rem;
    padding-bottom: 7.5rem;
	}
}