input[type="checkbox"] + label {
  display: inline-block;
  margin-left: .5rem;
  color: var(--form-label-color);
  font-weight: 300
}
input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media not print {
  input[type="checkbox"]::-ms-check {
    border-width: 1px;
    color: transparent;
    background: inherit;
    border-color: inherit;
    border-radius: inherit;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  display: inline-block;
  vertical-align: top;
  background-origin: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: var(--form-checkbox-color);
  background-color: var(--form-field-bg-color);
  border-style: solid;
  border-color: var(--form-field-border-color);
  border-width: 1px;
  border-radius: 0.25rem;
  margin-top: .25rem
}

input[type="checkbox"]:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--form-field-focus-shadow);
  border-color: var(--form-field-focus-border);
}

input[type="checkbox"]:checked:focus {
  border-color: transparent;
}
