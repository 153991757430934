@charset "utf-8";

/* ----------------------------------------------------------------------------
 * (i) Fonts importieren
 * -------------------------------------------------------------------------- */
@import url( "https://fonts.googleapis.com/css?family=Fredericka+the+Great|Merriweather:300,300i,400,400i,700,700i,900" );
//@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');

/* ----------------------------------------------------------------------------
 * (ii) Elements Styling System importieren
 * -------------------------------------------------------------------------- */
@import "klartext";

/* ----------------------------------------------------------------------------
 * (iii) Review Styles nur im Entwicklermodus einfügen
 * -------------------------------------------------------------------------- */

