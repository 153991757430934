@media (min-width: 1024px) {
  form {
    max-width: 40rem;
    margin: 0 auto;
  }
}

form {
  border-top: 1px solid var(--form-border-color);
  border-bottom: 1px solid var(--form-border-color);
  @include space-y;
  padding-bottom: 2rem;
  width: 100%;
  opacity: 0;
  font-family: sans-serif;
  animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) .35s 1 normal forwards;
  
  a {
    color: #00a0ff;
    text-decoration: underline;
    
    &:hover {
      text-decoration: none;
    }
  }
  & *:focus {
    outline: 0;
  }
  
  &.none {
    border: initial;
    padding-bottom: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  } 
  to { 
    opacity: 1;
  }
}

fieldset, .fieldset {
  border: 0;
  margin: 0;
  padding: 0 1em
}

legend {
  letter-spacing: .1em;
  text-transform: uppercase;
  text-align: center;
  color: var(--form-legend-color);
  margin-top: 2rem;
  border-bottom: 1px solid var(--form-legend-color);
  width: 100%;
  height: 2em;
  line-height: 2em;
}

.form-help-inline {
    font-size: x-small;
    display: block;
    margin-top: 1.25rem;
}

.form-help-inline code {
    background-color: var(--code-border-color);
    padding: .25rem;
    border-radius: .25rem
}
