
input[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media not print {
  input[type="radio"]::-ms-check {
    border-width: 1px;
    color: transparent;
    background: inherit;
    border-color: inherit;
    border-radius: inherit;
  }
}

input[type="radio"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-shrink: 0;
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  color: var(--form-radio-color);
  background-color: var(--form-field-bg-color);
  border-color: var(--form-field-border-color);
  border-width: 1px;
}

input[type="radio"]:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--form-field-focus-shadow);
  border-color: var(--form-field-focus-border);
}

input[type="radio"]:checked:focus {
  border-color: transparent;
}

