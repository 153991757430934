@mixin shadow {
	box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px
    2px 0 rgba(0, 0, 0, 0.06);
}
@mixin inset {
	top: 0px; 
	right: 0px; 
	bottom: 0px; 
	left: 0px;
}
@mixin space-y {
	margin-top: 1.75rem;
	margin-bottom: 1.75rem;
}