.sans-serif {
	font-family: $sans-serif
}
.serif {
	font-family: $serif
}
.fw-bolder {
	font-weight: bolder
}

/* Backgrounds (use as mixin class) ----------------------------------------- */
.bg-red {
	background-color: var(--bg-red)
}   
.bg-pink {
  background-color: var(--bg-pink)
}  
.bg-purple {
  background-color: var(--bg-purple)
}
.bg-indigo {
  background-color: var(--bg-indigo)
}
.bg-blue {
  background-color: var(--bg-blue)
}  
.bg-cyan {
  background-color: var(--bg-cyan)
}  
.bg-teal{
  background-color: var(--bg-teal)
}  
.bg-green{
  background-color: var(--bg-green)
} 
.bg-lime{
  background-color: var(--bg-lime)
}  
.bg-yellow {
  background-color: var(--bg-yellow)
}
.bg-orange {
  background-color: var(--bg-orange)
}
.bg-gray{
  background-color: var(--bg-gray)
}
/* Margins ------------------------------------------------------------------ */
.mr-1 {
	margin-right: .25rem
}

/* Orientierung Bilder im Fließtext ----------------------------------------- */
.left {
	float: left;
	margin-right: 1rem
}
.right {
	float: right;
	margin-left: 1rem
}
.w-2\/3 {
	max-width: 58.75rem;
	margin: 0 auto;
}
@media (min-width: 1024px) {
	.w-2\/3 {
	  padding-left: 11px;
	}
}
/* Icons ------------------------------------------------------------------- */
.icon {
	width: 1rem;
	height: 1rem;
}