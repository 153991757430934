select {
  width: 100%;
  top: .9em;
  z-index: 1;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  background: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  border: 0 solid var(--form-field-border-color);
  padding: .0625em;
  font-size: 1em;
  color: var(--form-label-color);
  font-weight: 300
}
select::-ms-expand {
  color: #9fa6b2;
  border: none;
}

@media not print {
  select::-ms-expand {
    display: none;
  }
}

@media print and (-ms-high-contrast: active), print and (-ms-high-contrast: none) {
  select {
    padding-right: 0.75rem;
  }
}
select:focus {
  outline: none;
  color: var(--form-field-focus-color)
}
select:valid {
  color: var(--form-field-valid-color)
}
