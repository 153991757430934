article { 
	em {
		&.key {
			font-variant: small-caps;
			font-style: normal;
			font-weight: inherit;
		}
	}
	em[lang] {
		font-weight: 300;
	}
}