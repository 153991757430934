
/* ----------------------------------------------------------------------------
 * (i) Variablen mit vernünftigen Grundeinstellungen einlesen.
 * -------------------------------------------------------------------------- */

@import "variables";
@import "mixins";
@import "utilities";

/* ----------------------------------------------------------------------------
 * (ii) Standard Farbschema setzen.
 * -------------------------------------------------------------------------- */

@import "skin/default";

/* ----------------------------------------------------------------------------
 * (iii) Vendorstile importieren.
 * -------------------------------------------------------------------------- */
@import "vendor/base";
@import "vendor/screen-readers";


/* ----------------------------------------------------------------------------
 * (iv) Basisstile importieren.
 * -------------------------------------------------------------------------- */
@import "typography/styles";
@import "code/styles";
@import "table/styles";
@import "form/styles";

/* ----------------------------------------------------------------------------
 * (v) Styles der Designelemente dazulesen. 
 * -------------------------------------------------------------------------- */

@import "element/styles";

/* ----------------------------------------------------------------------------
 * (vi) Styles für Meldungen aus der App.
 * -------------------------------------------------------------------------- */
@import "app/styles";
