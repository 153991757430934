article { 
  b,
  .b {
    font-weight: inherit;
    text-decoration: underline
  }
  i,
  .i {
    font-style: italic
  }
  mark, 
  .mark {
    background-color: var(--mark-color);
    padding-left: .15em;
    padding-right: .15em
  }
  q { 
    quotes: "»" "«";
    font-style: italic
  }
  sup, sub {
    font-size: 50%
  }
  kbd {
    padding: .125em .25em;
    font-size: .8em;
    color: var(--white);
    background-color: var(--font-color);
    border-radius: .125em;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.25);
  }
  small {
    font-size: .75em;
  }
}
/* cite, dfn, s, span, u */