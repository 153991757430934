
.search-results {
  position: absolute;
  left: 0;
  display: none;
  width: 100%;
  top: 100%;
  max-height: calc(100vh - 200%) !important;
  overflow-y: auto;
  background-color: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08)
}

.search-results-list {
  padding-left: 0;
  margin-bottom: .25rem;
  list-style: none;
  font-size: 14px !important
}

.search-results-list-item {
  padding: 0;
  margin: 0
}

.search-result {
  display: block;
  padding-top: .25rem;
  padding-right: .75rem;
  padding-bottom: .25rem;
  padding-left: .75rem
}

.search-result:hover, .search-result.active {
  background-color: #ebedf5
}
.search-result-title {
  display: block;
  padding-top: .5rem;
  padding-bottom: .5rem
}
.search-result-doc {
  display: flex;
  align-items: flex-start;
  word-wrap: break-word
}
.search-result-doc svg {
  margin-top: .25rem
}
.search-result-doc-description {
  margin-top: .25rem;
  opacity: 0.5;
  font-size: 12px !important;
  font-style: italic;
  font-weight: normal
}

.search-result-doc .search-result-icon {
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  color: #1d4ed8;
  flex-shrink: 0
}

.search-result-doc .search-result-doc-title {
  overflow: auto
}

.search-result-section {
  margin-left: 1.5rem;
  word-wrap: break-word
}

.search-result-rel-url {
  display: block;
  overflow: hidden;
  color: #959396;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 9px !important
}
.search-result-previews {
  display: block;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  margin-left: .5rem;
  color: #959396;
  word-wrap: break-word;
  border-left: 1px solid;
  border-left-color: #eeebee;
  font-size: 11px !important
}

.search-result-preview + .search-result-preview {
  margin-top: .25rem
}

.search-result-highlight {
  font-weight: bold
}

.search-no-result {
  padding-top: .5rem;
  padding-right: .75rem;
  padding-bottom: .5rem;
  padding-left: .75rem;
  font-size: 12px !important
}

.search-active .search-results {
  display: block
}
