
div#sidebar { 
	display: flex; 
	position: fixed; 
	z-index: 40;
	@include inset; 
}

div.overlay { 
	position: fixed;
	@include inset;

	&.enter { 
	  transition-property: opacity; 
	  transition-timing-function: linear; 
	  transition-duration: 300ms; 
	}
	&.enter-start { 
	  opacity: 0; 
	}
	&.enter-end { 
	  opacity: 1; 
	}
	&.leave { 
	  transition-property: opacity; 
	  transition-timing-function: linear; 
	  transition-duration: 300ms; 
	}
	&.leave-start { 
	  opacity: 1; 
	}
	&.leave-end { 
	  opacity: 0; 
	}
	
	.background { 
	  background-color: var(--sidebar-overlay-bg-color); 
	  opacity: 0.75; 
	  position: absolute; 
	  @include inset;
	}
}

div.sidebar { 
	background-color: var(--sidebar-bg-color); 
	display: flex; 
	flex-direction: column; 
	flex: 1 1 0%; 
	max-width: 20rem; 
	padding-bottom: 1rem; 
	padding-top: 1rem; 
	position: relative; 
	width: 100%; 
	
	&.enter { 
	  transition-property: transform; 
	  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); 
	  transition-duration: 500ms; 
	}
	&.enter-start { 
	  transform: translateX(-100%);  
	}
	&.enter-end { 
	  transform: translateX(0px); 
	}
	&.leave { 
	  transition-property: transform; 
	  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); 
	  transition-duration: 500ms; 
	}
	&.leave-start { 
	  transform: translateX(0px); 
	}
	&.leave-end { 
	  transform: translateX(-100%); 
	}
}

div.close-sidebar { 
  margin-right: -3rem; 
  padding-top: 0.5rem; 
  position: absolute; 
  top: 0px; 
  right: 0px;
	
	button { 
	  border-radius: 9999px; 
	  display: flex; 
	  align-items: center; 
	  justify-content: center; 
	  height: 2.5rem; 
	  margin-left: 0.25rem; 
	  width: 2.5rem;
	  color: var(--sidebar-close-button-color);
	  
	  &:hover {
	    color: var(--sidebar-close-button-hover-color);
	  }
	  
	  &:focus { 
	    outline: 2px solid transparent; 
	    outline-offset: 2px; 
	    box-shadow: inset 0 0 0 2px var(--white), inset 0 0 0 2px var(--white), 0 0 var(--black);
	  }
	  
	  svg { 
	    height: 1.5rem; 
	    width: 1.5rem; 
	  }
	}
}

div.logo { 
	display: flex; 
	align-items: center; 
	flex-shrink: 0; 
	padding-left: 1rem; 
	padding-right: 1rem; 
	
	img {  
	  height: 2rem; 
	  width: auto; 
	}
}

div.menu { 
	flex: 1 1 0%; 
	height: 0px; 
	margin-top: 1.25rem; 
	overflow-y: auto; 

	nav { 
	  padding-left: 0.5rem; 
	  padding-right: 0.5rem;
	   
	  & > :not([hidden]) ~ :not([hidden]) { 
	    margin-top: 0.25rem; 
	    margin-bottom: 0; 
	  } 
	}
}

svg.nav-item { 
	width: 1rem; 
	height: 1rem; 
	margin-right: 0.75rem; 
	color: var(--sidebar-item-color); 
}

.group:hover svg { 
	color: var(--sidebar-group-hover-color); 
}

a.nav, a.nav-sub { 
	border-radius: 0.375rem; 
	display: flex; 
	align-items: center; 
	font-weight: 500; 
	line-height: 1.25rem; 
}
a.nav {
  font-size: 0.875rem;
  padding: 0.5rem;	
}

a.nav-sub { 
	margin-left: 2.25rem; 
	font-size: 0.75rem;
	padding: 0.25rem 0.5rem; 
}

a.nav__active { 
	background-color: var(--sidebar-item-active-bg-color); 
	color: var(--sidebar-item-active-color); 
}

a.nav__inactive { 
	color: var(--sidebar-item-inactive-color); 
	
	&:hover { 
	  background-color: var(--sidebar-item-inactive-hover-bg-color); 
	  color: var(--sidebar-item-inactive-hover-color); 
	}
}

div.sidebar-spacer { 
  flex-shrink: 0; 
  width: 3.5rem; 
}
