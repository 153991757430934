.default-w {
  max-width: 40rem;
  margin: 0 auto;
}
article { 
  p, h1, h2, h3, h4, dl, ol, ul, blockquote, details {
    max-width: 40rem;
    margin: 0 auto;
  }
  p {
    padding: 0 1rem .5rem;
    font-weight: 300;
    
    &.initiale {
      margin-top: 3em;
    
      &:first-letter {
        font-family: $initiale-font;
        font-size: 3.5em;
        line-height: 1em;
        font-style: normal;
        color: $initiale-color;
      }
    }
    code {
      padding: .125em .25em;
      font-size: .8em;
      color: var(--inline-code-color);
      background-color: var(--inline-code-bg-color);
      border-radius: 4px;
    }
  }
  h2 {
    padding: 1.5rem 1rem .5rem;
    font-weight: 700;
    font-size: 1.5rem
  }
  h3 {
    padding: 1rem 1rem .5rem;
    font-weight: 700;
    font-size: 1.25rem
  }
  h4 {
    padding: .75rem 1rem .5rem;
    font-weight: 700
  }
  blockquote {
    background-color: var(--blockquote-bg-color);
    border-left-width: .25rem;
    border-left-style: solid;
    border-left-color: var(--blockquote-border-color);
    color: var(--blockquote-color);
    font-style: italic;
    margin-bottom: 1.25rem;
    
    p {
      padding: .5rem 1rem .5rem .75rem
    }  
  }
  dl {
    padding: 0 1rem .5rem;
    
    dt {
      font-weight: bolder
    }
    dd {
      font-style: italic;
      margin-left: 1em
    }
  }
  ol {
    padding: 0 1rem .5rem;
    list-style: decimal;
    list-style-position: inside;
  }
  ul {
    padding: 0 1rem .5rem;
    list-style: circle;
    list-style-position: inside
  }
  details > p {
    font-family: $sans-serif;
    font-size: smaller;
    padding: 0 7.5rem .75rem 0;
  }
}
@media (min-width: 1024px) {
	.default-w {
    padding: 0 1rem 1rem
  }
  article { 
    p, h1, h2, h3, h4, dl, ol, ul, blockquote, details {
      padding: 0 1rem 1rem
    }	
  }
}