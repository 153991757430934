blockquote.testimonial {
	position: relative;
  padding-left: 7rem; 
  padding-top: .75rem;
  @include space-y;
  	
	img.avatar {
	  position: absolute;
	  padding: .7%;
		border-radius: 50%;
		background: var(--testimonial-avatar-border-color);
	  width: 6rem;
	  top: .75rem;
	  left: .75rem;
	}
}