#cookie-notice {
	position: fixed;
	bottom: 0;
	right: 0;
  left: 0;
  padding-bottom: 0.5rem;
  font-family: $sans-serif;
  
  div.wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    
    div.shadow {
      padding: 0.5rem;
      border-radius: 0.5rem;
      background-color: var(--cc-bg-color);
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      
      div.content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        
        div.message {
          width: 0;
          flex: 1 1 0%;
          display: flex;
          align-items: center;
          
          span.icon {
            display: flex;
            padding: 0.5rem;
            border-radius: 0.5rem;
            background-color: var(--cc-icon-bg-color);
          }
          
          p.message {
            margin: 0 0 0 0.75rem;
            max-width: inherit;
            padding: inherit;
            font-weight: 500;
            color: var(--cc-message-color);
            overflow: hidden;
					  text-overflow: ellipsis;
					  white-space: nowrap;
					  
					  span.small {
              display: block
            }
            span.large {
              display: none
            }
          }
        }
        
        div.accept {
          order: 3;
          margin-top: 0.5rem;
          flex-shrink: 0;
          width: 100%;
        }
        div.deny {
          order: 2;
          flex-shrink: 0;
          
        }
      }
    }
  }
	svg.icon {
    height: 1.5rem;
    width: 1.5rem;
    color: var(--cc-svg-icon-color);
  }
  button#deny-cookies {
    margin-right: -0.25rem;
    display: flex;
    padding: 0.5rem;
    border-radius: 0.375rem;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    
    &:hover {
      background-color: var(--cc-button-hover-color)
    }
    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      background-color: var(--cc-button-hover-color)
    }
  }
  button#accept-cookies {
    width: 100%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem 1rem;
    border-width: 1px;
    border-color: transparent;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    border-radius: 0.375rem;
    color: var(--cc-button-color);
    background-color: var(--cc-accept-button-bg-color);
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    
    &:hover {
      color: var(--cc-button-hover-color)
    }
    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      box-shadow: 0 0 0 3px var(--cc-accept-button-shadow)
    }
  }
}
@media (min-width: 1024px) {
	#cookie-notice {
	  padding-bottom: 1.25rem;
	  
	  div.wrapper {
	    padding-left: 1.5rem;
      padding-right: 1.5rem;
      
      div.shadow {
        padding: 0.75rem;
        
        div.content {
	        div.message {
	          p.message {
	            span.small {
	              display: none
	            }
	            span.large {
                display: inline-block
              }
	          }
	        }
	        
	        div.accept {
	          order: 2;
	          margin-top: 0;
	          margin-left: 0.5rem;
	          width: auto
	        }
	        div.deny {
	          order: 3;
	          margin-left: 0.5rem
	        }
	      }
	    }
	  }
	}
}