.box {
  width: 100%;
  margin-top: .5rem;
  display: flex
}
.grid {
  margin: 0 0 .75rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: auto;
  justify-content: stretch;
  place-items: stretch;
  gap: 0 0
}
.col-span-1 {
  grid-column: auto / span 1; 
  position: relative; 
   width: 100% 
}

.col-span-2 {
  grid-column: auto / span 2; 
  position: relative;
   width: 100% 
}

.col-span-3 {
  grid-column: auto / span 3; 
  position: relative;
   width: 100% 
}

.col-span-4 {
  grid-column: auto / span 4; 
  position: relative;
  width: 100% 
}
.field {
  width: 100%;
  position: relative;
  height: 2.5em;
  border: 0px solid var(--form-field-border-color);
  border-bottom-width: 1px
}
.border-right {
  border-right-width: 1px;
}
.padding-left label, .padding-left input {
  margin-left: .25em;
}
.padding-left select {
  margin-left: .25em;
}