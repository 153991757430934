article header {
	margin-top: 1rem;
  padding: 0 1rem;
  
	&.video {
	  padding-top: 1.75rem;
	  border-color: var(--nav-border-color);
	  border-top-width: 1px;
	}
	h1 {
	  font-size: 3rem;
	  font-weight: 900;
	  line-height: 1.5;
	  text-align: left;


	  span {
	    display: block;
	    font-family: $sans-serif;
	    font-weight: 400;
	    font-size: .9rem;
	    color: var(--header-kicker-color);

			img {
				float: left;
				width: .9rem;
				height: auto;
				margin: .25rem .25rem 0 0;
			}
	  }	
	}
	h2 {
		display: flex;
		justify-content: left;
		
		img {
		  width: 1.5rem;
		}
	}
	figure {
	  margin-top: 0;
	  margin-bottom: 0;
	  position: relative;
	  
	  div.footer {
	    position: absolute;
	    font-size: small;
	    padding: .25rem .5rem;
	    border: none;
	    bottom: 0;
	    margin: 0;
	    right: 0;
	    color: var(--header-figure-bg-color);
	    text-shadow: #000 1px 1px 1px;
	  }
	}
	p.teazer {
	  font-family: $serif;
	  color: var(--header-description-color); 
	  font-style: italic;
	  font-size: larger;
	  text-align: left;
	  padding: 0;
	  
	  em {
	    font-style: italic; 
	  }
	  img {
	    float: left;
	    margin: 7px 1rem 0 0;
	  }
	}
	p.meta {
	  color: var(--header-meta-color);
	  font-size: smaller;
	  text-align: left;
	}
}
@media (min-width: 1024px) {
	
	article header {
	  p.teazer {
	    padding: 0 1rem .5rem;
	  }
	}
}