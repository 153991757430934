article { 
	a {
	  background-color: transparent;
	  border-color: var(--link-color);
	  border-style: solid;
	  border-width: 0 0 1px;
	  box-shadow: inset 0 -4px 0 var(--link-color);
	  color: inherit;
	  text-decoration: none;
	  transition: background-color 250ms ease, color 250ms ease, box-shadow 250ms ease, border-color 250ms ease;
	
	  &:hover, &.citation:hover, &.keyword:hover {
	    color: var(--font-color);
	    box-shadow: initial;
	  }
	  svg {
	    width: 1rem;
	    height: 1rem;
	    display: inline-block;
	  }
	  
	  &.citation {
	    box-shadow: inset 0 -1px 0 var(--link-color);
	  }
	  &.keyword {
      box-shadow: inset 0 -1px 0 var(--link-color); 
      font-variant: small-caps; 
    }
	}
	a[role=button] {
	  box-shadow: initial;
	  border: initial;
	  text-decoration: none;
	}
	em, 
	.em {
	  font-weight: $em-weight;
	  
	  i {
	    font-style: normal;
	  }
	}
	strong, 
	.strong {
	  font-weight: bolder;
	  
	  &.callout {
	    display: table;
	    background-color: var(--callout-bg-color);
	    padding: .55rem;
	    border-left: .25rem solid var(--callout-border-color);
	    margin-left: -.25rem;
	    margin-bottom: 2rem;
	    width: 11rem;
	  }
	}
	abbr {
	  font-style: italic;
	}
	img.person {
		width: auto;
		height: 150px;
		padding: .7%;
		border-radius: 50%;
		background: var(--avatar-bg-color);
	}
}
/* code @see _block.scss p */
/* img @see ../element/_figure.scss */